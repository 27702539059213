import React, { useContext, useEffect } from "react";
import ChatHeader from "../../components/ChatWiget/ChatComponents/components/ChatHeader";
import ChatBody from "../../components/ChatWiget/ChatComponents/components/ChatBody";
import SearchBar from "../../components/ChatWiget/ChatComponents/components/SearchBar";
import { GlobalStateContext } from "../../components/ChatWiget/ContextState";
import { useSearchParams } from "react-router-dom";

const ChatWidgetPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { isExpanded, chatData, tab, setIsIframe, setUserName, setTenantId } =
    useContext(GlobalStateContext);

    useEffect(() => {
        setIsIframe(true)
    },[])

    useEffect(() => {
        setUserName(searchParams.get("emp_name"))
        setTenantId(searchParams.get("tenant_id"))

    },[searchParams])
    return (
        <div className="h-[100%] ">
        {true && (
            <div
            className={`flex flex-col overflow-y-scroll hide-scrollbar shadow-outer  bg-white 
                h-[100vh] min-[320px]:h-[95vh] rounded-[10px] overflow-x-auto no-scrollbar`}
            >
            <div className="sticky top-0 w-[100%] bg-white ">
                <ChatHeader />
            </div>
            {/* <hr className="drop-shadow-lg" /> */}
            <div
                // className="mb-[10%] flex flex-col mt-[auto] min-h-[70vh]"
                className={` ${
                chatData.length > 0 && tab === "home"
                    ? "mb-[5%] flex flex-col mt-[auto]"
                    : "min-h-[75vh]"
                }`}
            >
                <ChatBody />
            </div>
            {/* <div>
                {tab === "home" && (
                <div className="sticky bottom-0 w-[100%] bg-slate-900 "></div>
                )}
            </div> */}
            <div className="fixed bottom-0 w-[100%] bg-white ">
                {tab === "home" && <SearchBar />}
                {/* <ChatFooter /> */}
            </div>
            </div>
        )}
        {/* <button
            className="absolute bottom-0 right-0 lg:mr-[50px] min-[320px]:mr-[20px] mb-[20px] rounded-3xl bg-[#156FEF] w-[50px] h-[50px] text-[white] flex justify-center items-center"
            onClick={() => setIsChatOpen(!isChatOpen)}
        >
            {isChatOpen ? <Xicon /> : <Iicon />}
        </button> */}
        </div>
    );
};

export default ChatWidgetPage;
